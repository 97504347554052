import Oidc, { UserManager, WebStorageStateStore } from "oidc-client";

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.DEBUG;
const settings: any = {
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    authority: process.env.VUE_APP_OIDC_AUTHORITY,
    client_id: process.env.VUE_APP_OIDC_CLIENT_ID,
    redirect_uri: `${window.location.origin}/callback.html`,
    automaticSilentRenew: true,
    silent_redirect_uri: `${window.location.origin}/silent-renew.html`,
    checkSessionInterval: 5000,
    response_type: 'code',
    scope: 'openid email profile',
    post_logout_redirect_uri: `${window.location.origin}`,
    loadUserInfo: true,
    
};

export default new UserManager(settings);