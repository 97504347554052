
import Vue from 'vue'
import Hero from '@/components/Hero.vue';
import MainLayout from '@/components/MainLayout.vue';
import Features from '@/components/Features.vue';
import UseCases from '@/components/UseCases.vue';
import DiscoverYourMatches from '@/components/DiscoverYourMatches.vue';
import JoinUs from '@/components/JoinUs.vue';

export default Vue.extend({
    name: 'Home',
    components: {
        Hero,
        Features,
        MainLayout,
        UseCases,
        DiscoverYourMatches,
        JoinUs
    }
});
