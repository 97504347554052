
import Vue from 'vue'
import Footer from '@/components/Footer.vue';

export default Vue.extend({
    name: 'MainLayout',
    components: {
        Footer
    },
    data() {
        return {
            showNavigationDrawer: false,
            list: [
                { label: "Home", to: process.env.VUE_APP_HELPERAI_OFFICIAL_URL, target: ''},
                { label: "Product", to: "/", target: ''},
                { label: "Partnerships", to: "/#partnerships", target: ''},
                { label: "Contact us", to: "mailto:" + process.env.VUE_APP_HELPERAI_CONTACT_EMAIL, target: '_blank'}
            ]
        };
    },
    computed: {
        typeFormUrl() {
            return process.env.VUE_APP_CONTACT_US_TYPEFORM;
        },
        linkedinMatchUrl() {
            return process.env.VUE_APP_NAMECARD_LINKEDIN_MATCH_URL_YC;
        }
    },
    methods: {
    }
})
