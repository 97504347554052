import { Commit } from 'vuex'
import * as types from '../mutation-types'
import { UserDto } from '@chainsight/networker-backend-axios-sdk'
import jwt_decode from "jwt-decode";
import api from '@/api';
import { Profile, User, UserManager, WebStorageStateStore } from 'oidc-client';
import { use } from 'vue/types/umd';
import userManager from '@/user-manager';
import router from '@/router';

export function userManagerPlugin(store) {
    userManager.events.addUserSignedIn(() => {
        store.dispatch('updateSignInState');
    });
    userManager.events.addUserSignedOut(() => {
        store.dispatch('updateSignInState');
    });
    store.dispatch('updateSignInState');
}

export interface AccessTokenMutationPayload {
    accessToken: string,
    profile: Profile,
    userDto: UserDto,
}


export interface State {
    isSignedIn: boolean | null,
    accessToken: string | null,
    profile: Profile | null,
    userDto: UserDto | null,
}

// initial state
const initState: State = {
    isSignedIn: null,
    accessToken: null,
    profile: null,
    userDto: null,
}

// getters
const getters = {
}

// actions
const actions = {
    updateSignInState(context: { commit: Commit; state: State }) {
        userManager.getUser().then(user => {
            if (!user || user.expired) {
                // Uncomment this is line if the website always requires user to sign in. 
                // userManager.signinRedirect();
            } else {
                api.authApi.getMe()
                    .then(resp => {
                        context.commit(types.SIGNED_IN, { accessToken: user?.access_token, profile: user.profile, userDto: resp.data.data });
                    }, console.error);

            }
        }, console.error);
    },
}

// mutations
const mutations = {
    [types.SIGNED_IN](state: State, payload: AccessTokenMutationPayload) {
        state.isSignedIn = true;
        state.accessToken = payload.accessToken;
        state.profile = payload.profile;
        state.userDto = payload.userDto;
    },
    [types.SIGNED_OUT](state: State) {
        state.isSignedIn = false;
        state.accessToken = null;
        state.profile = null;
        state.userDto = null;
    },
}

export default {
    state: initState,
    getters,
    actions,
    mutations,
}