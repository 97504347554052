import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { ApiPlugin } from './plugins/api'
import '@babel/polyfill'
import HighchartsVue from "highcharts-vue";
import api from './api'
import injectInitialState from './utils/inject-initial-state';
import "@/styles/fonts.scss";
import '@/styles/global.scss';

Vue.config.productionTip = false
Vue.use(HighchartsVue);
Vue.use(ApiPlugin, { api });

const app = new Vue({
  router,
  store,
  vuetify,
  mounted() {
    document.dispatchEvent(new Event('render-event'));
  },
  render: h => h(App)
});

// During pre-rendering the initial state is
// injected into the global scope, here we
// fill the store with the initial state.
if ((<any>window).__INITIAL_STATE__) store.replaceState((<any>window).__INITIAL_STATE__);

router.beforeResolve(async (to, from, next) => {
  try {
    const components = router.getMatchedComponents(to);

    // By using `await` we make sure to wait
    // for the API request made by the `fetch()`
    // method to resolve before rendering the view.
    await Promise.all(components.map(x => (<any>x).fetch && (<any>x).fetch({ store })));

    // The `injectInitialState()` function injects
    // the current state as a global variable
    // `__INITIAL_STATE__` if the page is currently
    // pre-rendered.
    if ((<any>window).__PRERENDER_INJECTED) injectInitialState(store.state);
  } catch (error) {
    // This is the place for error handling in
    // case the API request fails for example.
    console.error(error);
  }

  return next();
});

app.$mount('#app')