
import Vue from 'vue';
import MainLayout from '@/components/MainLayout.vue';

export default Vue.extend({
    name: 'PrivacyView',
    components: {
        MainLayout
    },
    data() {
        return {};
    },
    computed: {},
    methods: {},
    created() {
    }
});
