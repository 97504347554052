import _Vue from "vue";
import { Api } from "@/api";

export function ApiPlugin(Vue: typeof _Vue, options: ApiPluginOptions): void {

    (Vue as any).api$ = options.api;
    Vue.mixin({
        data: () => {
            return {
                api$: options.api
            };
        }
    });
}

export interface ApiPluginOptions {
    api: Api,
}