
import Vue from 'vue'

export default Vue.extend({
    name: 'UseCases',
    components: {
    },
    computed: {
        linkedinMatchUrl() {
            return process.env.VUE_APP_NAMECARD_LINKEDIN_MATCH_URL_YC;
        }
    },
    data(){
        return{
            currentSlide: 0,
        }
    },
    methods: {
        changeSlide(index) {
        this.currentSlide = index;
        }
    }
})
