import axios, { AxiosError, AxiosInstance } from "axios";
import { Configuration, AuthApi } from '@chainsight/networker-backend-axios-sdk';
import store from "@/store";
import userManager from "@/user-manager";

export const JWT_STORAGE_KEY = 'CHAINSIGHT_JWT';
export class Api {
    public authApi: AuthApi;
    constructor(config: Configuration, baseUrl: string, axios: AxiosInstance) {
        this.authApi = new AuthApi(config, baseUrl, axios);
    }
}

export const axiosInst = axios.create({
    baseURL: process.env.VUE_APP_API_BASE_URL,
    timeout: 30000,
});
axiosInst.interceptors.response.use((response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
}, (error: AxiosError) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    console.error(error);
    if (error.response?.status == 401 || error.response?.status == 403) {
        console.error('Unauhtorized request dectected', error);
        userManager.signoutRedirect();
        
    }
    return Promise.reject(error);
});

const apiConfig = new Configuration({
    accessToken: async () => {
        const user = await userManager.getUser()
        if (user) {
            return user.access_token;
        }
        return '';
    }
})

export default new Api(apiConfig, process.env.VUE_APP_API_BASE_URL, axiosInst);