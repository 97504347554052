
import Vue from 'vue'
export default Vue.extend({
    name: 'Footer',
    components: {
    },
    computed: {
        discordUrl() {
            return process.env.VUE_APP_YOUTUBE_URL;
        },
		twitterUrl() {
            return process.env.VUE_APP_TWITTER_URL;
        },
		facebookUrl() {
            return process.env.VUE_APP_FACEBOOK_URL;
        },
		instagramUrl() {
            return process.env.VUE_APP_INSTAGRAM_URL;
        },
		linkedinUrl() {
            return process.env.VUE_APP_LINKEDIN_URL;
		}
    },
})
