
import Vue from 'vue'

export default Vue.extend({
    name: 'JoinUs',
    components: {
    },
    computed: {
        typeFormUrl() {
            return process.env.VUE_APP_CONTACT_US_TYPEFORM;
        }
    },
    data(){
        return{
            backgroundImage: require('@/assets/JoinUs/background.png'),
        }
    },
    methods: {
    },
    mounted() {
    }
})
