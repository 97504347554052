import { UserManager } from 'oidc-client'
import Vue from 'vue'
import Vuex, { Commit, Dispatch } from 'vuex'
import actions from './actions'
import getters from './getters'
import user, { State as UserState, userManagerPlugin } from './modules/user'

// Reference: https://github.com/qidaizhe11/vue-vuex-typescript-demo



Vue.use(Vuex);

export default new Vuex.Store({
  actions,
  getters,
  modules: {
    user,
  },
  plugins: [
    userManagerPlugin
  ]
})

export interface ActionContextBasic {
  commit: Commit,
  dispatch: Dispatch
}

export interface State {
  user: UserState
}