import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify);

export default new Vuetify({
    icons: {
        iconfont: 'mdi',
    },
    theme: {
        themes: {
            light: {
                primary: {
                    darken2: '#FE8C10',
                    darken1: '#FCBC14',
                    base: '#FFFB00',
                },
                "highlight-1": '#4BC4E0',
                "highlight-2": '#BBE8F2',
                "highlight-3": '#E9FEFF',
                error: '#E40E0E',
                red: '#E40E0E',
                success: '#05C544',
                green: '#05C544',
                grey: {
                    darken4: '#414f4c',
                    darken3: '#586562',
                    darken2: '#707879',
                    darken1: '#8C8C8C',
                    base: '#88918f',
                    lighten1: '#9fa7a5',
                    lighten2: '#b8bdbc',
                    lighten3: '#cfd3d2',
                    lighten4: '#e7e9e9',
                    lighten5: '#fafbfb',
                },
            },
        },
    },
});
